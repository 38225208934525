.dropdown-toggle::after {
  display: none !important;
}

.template-dropdown :hover {
  background-color: transparent;
}

@media print {
  body {
    visibility: hidden;
  }

  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page {
    size: auto;
    margin: 0mm;
  }
}

.anticon svg {
  color: #ffffff;
}

.ant-picker-input input {
  color: #ffffff !important;
}

.ant-picker-input input::placeholder {
  color: #ffffff !important;
  opacity: 0.75;
}

.activated-tag {
  position: absolute;
  top: 2rem;
  right: -3rem;
  transform: rotate(45deg);
  z-index: 1;
  padding: 0.5rem 4rem;
}